import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { withTranslation } from "react-i18next";

import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import OrderRow from "./OrderRow";

class OrdersTable extends React.Component {
  render() {
    const { t } = this.props;
    const { classes, orders, startDate } = this.props;
    let previousDate = startDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;

    return (
      <Paper className={classes.root}>
        <Table padding="checkbox">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("godzina")}</TableCell>
              <TableCell align="center">{t("trasa")}</TableCell>
              <TableCell align="center">{t("pasazer")}</TableCell>
              <TableCell align="center">{t("iloscosob")}</TableCell>
              <TableCell align="center">{t("kierowca")}</TableCell>
              <TableCell align="center">Nr. Taxi</TableCell>
              <TableCell align="center">{t("kwotafirma")}</TableCell>
              <TableCell align="center">{t("kwotapasazer")}</TableCell>
              <TableCell align="center">{t("ccmpkkarta")}</TableCell>
              <TableCell align="center">{t("zamawiajacy")}</TableCell>
              <TableCell align="center">{t("nrlotu")}</TableCell>
              <TableCell align="center">{t("uwagi")}</TableCell>
              <TableCell align="center">{t("notatki")}</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map(order => {
              let ret = [];
              let currentDate = moment(order.when).format("YYYY-MM-DD");

              if (previousDate && previousDate !== currentDate) {
                ret.push(
                  <TableRow key={"dateSratata" + order.id}>
                    <TableCell
                      align="center"
                      colSpan="20"
                      style={{
                        padding: "0 14px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        backgroundColor: "#e8e8e8",
                        textAlign: "left"
                      }}
                    >
                      {moment(currentDate).format("dddd, D MMMM YYYY")}
                    </TableCell>
                  </TableRow>
                );
              }
              previousDate = currentDate;

              ret.push(
                <OrderRow
                  key={"orderrow" + order.id + "-" + order.identifier}
                  data={order}
                  disableEditing={this.props.disableEditing}
                  simpleDialogOpen={data => this.props.simpleDialogOpen(data)}
                  simpleDialogClose={this.props.simpleDialogClose}
                  refreshOrders={this.props.refreshOrders}
                  editOrder={data => this.props.editOrder(data)}
                />
              );
              return ret;
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflow: "visible",
    overflowX: "auto"
  }
});

export default withStyles(styles)(
  withTranslation("translations")(withSnackbar(OrdersTable))
);
