import React, { Component } from "react";
import axios from "axios";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withSnackbar } from "notistack";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";

import AddressRow from "./AddressRow";
import AddressInput from "../AddressInput";

class Addresses extends Component {
  state = {
    formOpen: false,
    formBusy: false,
    newAddress: { name: "", address: "" },
    addresses: []
  };

  componentDidMount() {
    axios.get(`addresses`).then(res => {
      const addresses = res.data.addresses;
      this.setState({ addresses });
    });
  }

  handleFormOpen = () => {
    this.setState({ formOpen: true, newAddress: { name: "", address: "" } });
  };

  handleFormClose = () => {
    this.setState({ formOpen: false });
  };

  handleInputChange = (e, a) => {
    let { name, value } = e.target;
    let newAddress = this.state.newAddress;

    newAddress[name] = value;
    this.setState({ newAddress });
  };

  handleAddressChange = address => {
    let newAddress = this.state.newAddress;

    newAddress.address = address;
    this.setState({ newAddress });
  };

  addressSave = () => {
    if (
      !this.state.newAddress.address ||
      (this.state.newAddress.address && !this.state.newAddress.address.address)
    ) {
      this.props.enqueueSnackbar("Wypełnij wymagane pola", {
        variant: "error"
      });
      return false;
    }

    if (this.state.newAddress.id) {
      this.addressUpdate();
    } else {
      this.addressCreate();
    }
  };

  addressUpdate = () => {
    this.setState({ formBusy: true });
    let address = { ...this.state.newAddress };

    axios
      .put("addresses/" + address.id, address)
      .then(response => {
        let addresses = this.state.addresses;
        let newAddress = response.data.address;
        let pos = addresses.map(e => e.id).indexOf(newAddress.id);

        addresses[pos] = newAddress;
        this.setState({ addresses, formBusy: false, formOpen: false });
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
        this.setState({ formBusy: false });
      });
  };

  addressCreate = () => {
    let address = { ...this.state.newAddress };

    this.setState({ formBusy: true });
    axios
      .post(`addresses`, address)
      .then(res => {
        let addresses = this.state.addresses;

        addresses.push(res.data.address);
        this.setState({ addresses, formBusy: false, formOpen: false });
      })
      .catch(err => {
        this.setState({ formBusy: false });
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  openEditHandler = data => {
    let newAddress = { ...data };

    this.setState({ newAddress, formOpen: true });
  };

  deleteHandler = data => {
    axios
      .delete("addresses/" + data.id)
      .then(response => {
        let addresses = this.state.addresses;
        let pos = addresses.map(e => e.id).indexOf(data.id);

        delete addresses[pos];
        this.setState({ addresses });
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  render() {
    let { addresses, formBusy, newAddress } = this.state;
    const { classes, t } = this.props;
    let formDisabled =
      newAddress.address && newAddress.address.address ? false : true;

    return (
      <div className="padding15vw">
        <Fab
          color="secondary"
          aria-label="dodaj"
          className={classes.addButton}
          onClick={this.handleFormOpen}
        >
          <AddIcon />
        </Fab>
        <Dialog
          open={this.state.formOpen}
          onClose={this.handleFormClose}
          aria-labelledby="form-dialog-add"
          classes={{ paperScrollPaper: classes.overflow }}
        >
          <ValidatorForm
            ref="form"
            onSubmit={this.addressSave}
            className="dialogModal"
          >
            <DialogContent className={classes.overflow}>
              <TextValidator
                autoFocus
                margin="dense"
                label={t("nazwa")}
                name="name"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newAddress.name}
                validators={["required"]}
                errorMessages={[t("requiredfield")]}
              />

              <AddressInput
                onSelect={this.handleAddressChange}
                selectedAddress={
                  newAddress.address.address ? newAddress.address.address : ""
                }
                selectedAddressObj={newAddress.address}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleFormClose} color="primary">
                {t("anuluj")}
              </Button>
              <div className={classes.relative}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={formBusy || formDisabled}
                >
                  {t("zapisz")}
                </Button>
                {formBusy && (
                  <CircularProgress
                    size={24}
                    color="secondary"
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
        <Paper className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("nazwa")}</TableCell>
                <TableCell>{t("adres")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addresses.map(data => {
                return (
                  <AddressRow
                    key={data.id}
                    data={data}
                    onEdit={() => this.openEditHandler(data)}
                    onDelete={() => this.deleteHandler(data)}
                  />
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  addButton: {
    position: "fixed",
    top: "68px",
    right: "20px",
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      top: "auto",
      bottom: "20px"
    }
  },
  roleInput: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  relative: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  dialog: {
    minWidth: "60vw"
  },
  overflow: {
    overflow: "visible"
  }
});

export default withSnackbar(withStyles(styles)(withTranslation("translations")(Addresses)));
