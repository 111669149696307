import React, { Component } from "react";
import axios from "axios";
import _ from "lodash";
import fileSaver from "file-saver";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Hidden from "@material-ui/core/Hidden";
import Fab from "@material-ui/core/Fab";
import { withSnackbar } from "notistack";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import pl from "react-date-range/dist/locale/pl";
import enGB from "react-date-range/dist/locale/en-GB";

import moment from "moment";
import i18next from "i18next";

import OrdersTable from "./OrdersTable";
import OrderForm from "./OrderForm";
import SimpleDialog from "../SimpleDialog";

class Orders extends Component {
  constructor(props) {
    super(props);

    let now = new Date();

    this.state = {
      formOpen: false,
      simpleDialogOpen: false,
      simpleDialogContent: "",
      editedOrder: {},
      orders: [],
      filter: "",
      pickerOpen: false,
      tempDateRange: {
        startDate: now,
        endDate: now,
        key: "selection"
      },
      dateRange: {
        startDate: moment(now).format("YYYY-MM-DD"),
        endDate: moment(now)
          .add(30, "day")
          .format("YYYY-MM-DD")
      }
    };
  }

  componentDidMount() {
    this.refresh();
    this.interval = setInterval(this.refresh, 300000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  refresh = () => {
    let params = { ...this.state.dateRange };

    axios
      .get(`orders`, { params: params })
      .then(res => {
        const orders = res.data.orders;
        this.setState({ orders: orders, filter: "", editedOrder: {} });
      })
      .catch(err => {
        this.props.enqueueSnackbar("Błąd! Spróbuj później", {
          variant: "error"
        });
      });
  };

  onCreated = order_id => {
    if (order_id) {
      this.props.enqueueSnackbar("Zlecenie zaktualizowane", {
        variant: "success"
      });
    } else {
      this.props.enqueueSnackbar("Zlecenie pomyślnie złożone", {
        variant: "success"
      });
    }

    this.refresh();
  };

  handleFormOpen = () => {
    this.setState({ formOpen: true, editedOrder: {} });
  };

  handleFormClose = () => {
    this.setState({ formOpen: false });
  };

  openEditHandler = data => {
    let editedOrder = { ...data };

    this.setState({ editedOrder, formOpen: true });
  };

  handleDeleteConfirmation = () => {
    let order = this.state.editedOrder;

    axios
      .delete("orders/" + order.id)
      .then(response => {
        this.closeDialog();
        this.refresh();
      })
      .catch(err => {
        this.closeDialog();
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  closeDialog = () => {
    this.setState({ editedOrder: null, dialogOpen: false });
  };

  handleDateSelect = ranges => {
    let newDate = {
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection"
    };

    this.setState({ tempDateRange: newDate });
  };

  confirmDateRanges = () => {
    let newDate = {
      startDate: moment(this.state.tempDateRange.startDate).format(
        "YYYY-MM-DD"
      ),
      endDate: moment(this.state.tempDateRange.endDate).format("YYYY-MM-DD")
    };

    this.setState({ dateRange: newDate }, () => this.refresh());
    this.togglePicker();
  };

  togglePicker = () => {
    let newPicker = !this.state.pickerOpen;

    this.setState({ pickerOpen: newPicker });
  };

  handleFilterChange = (e, a) => {
    this.setState({ filter: e.target.value });
  };

  simpleDialogClose = () => {
    this.setState({ simpleDialogOpen: false, simpleDialogContent: "" });
  };

  simpleDialogOpen = data => {
    this.setState({ simpleDialogOpen: true, simpleDialogContent: data });
  };

  getCSV = () => {
    let params = {
      startDate: this.state.dateRange.startDate,
      endDate: this.state.dateRange.endDate
    };

    axios
      .get("orders/xlsx", {
        params: params,
        responseType: "text",
        headers: { "Content-Type": "application/octet-stream" }
      })
      .then(res => {
        var byteCharacters = atob(res.data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        fileSaver.saveAs(blob, "fly.xlsx");
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Nie udało się pobrać zleceń";

        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  render() {
    const { t } = this.props;
    let {
      orders,
      formOpen,
      pickerOpen,
      dialogOpen,
      dateRange,
      filter
    } = this.state;
    const { classes } = this.props;
    let dateRangeOutput =
      dateRange.endDate === dateRange.startDate
        ? moment(dateRange.startDate).format("dddd, DD.MM")
        : moment(dateRange.startDate).format("DD.MM") +
          " - " +
          moment(dateRange.endDate).format("DD.MM");
    let filteredOrders = orders ? orders : [];

    // filtering of orders
    if (filter) {
      let regex = "";
      let filters = filter.toLowerCase().split(" ");

      filters.forEach(filter => {
        regex += "(?=.*" + filter + ")";
      });
      regex += ".*";

      filteredOrders = _.filter(orders, order => {
        let arr = [
          order.client.name,
          order.client.tel,
          order.costCenter,
          order.from.address,
          order.to.address,
          order.notes,
          order.orderer.name,
          order.paymentAmount,
          order.paymentMaker === "client" ? "Pasażer" : "Firma"
        ];

        order.through.forEach(through => {
          arr.push(through.address);
        });

        let srex = new RegExp(regex, "gm");

        return srex.exec(arr.join(" ").toLowerCase()) !== null;
      });
    }

    let heading = (
      <div className={classes.flex}>
        <div>
          <Tooltip title="Excel" placement="right">
            <IconButton onClick={this.getCSV}>
              <Icon>assignment_returned</Icon>
            </IconButton>
          </Tooltip>
          <Hidden smDown>
            <Tooltip title={t("odswiez")} placement="right">
              <IconButton
                aria-label="Odśwież"
                color="primary"
                onClick={this.refresh}
              >
                <Icon>refresh</Icon>
              </IconButton>
            </Tooltip>
          </Hidden>
        </div>
        <div className={classes.date} onClick={this.togglePicker}>
          <Typography variant="h5" gutterBottom style={{ marginBottom: 0 }}>
            {dateRangeOutput}
          </Typography>
          <ExpandMoreIcon style={{ cursor: "pointer", marginLeft: ".3em" }} />
        </div>
        <Hidden smDown>
          <Input
            placeholder={t("filtruj")}
            value={this.state.filter}
            onChange={this.handleFilterChange}
            style={{ marginRight: "80px" }}
          />
        </Hidden>
      </div>
    );

    let picker = null;

    if (pickerOpen) {
      picker = (
        <Dialog open={true} onClose={this.togglePicker}>
          <DialogContent>
            <DateRange
              locale={i18next.languages[0] === "pl-PL" ? pl : enGB}
              ranges={[this.state.tempDateRange]}
              onChange={this.handleDateSelect}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.togglePicker}>{t("anuluj")}</Button>
            <Button onClick={this.confirmDateRanges} color="primary">
             {t("zastosuj")} 
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    let dialog = null;

    if (dialogOpen) {
      dialog = (
        <Dialog
          open={true}
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
        >
          <DialogContent>{t("chceszanulowac")}</DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog}>{t("nie")}</Button>
            <Button onClick={this.handleDeleteConfirmation} color="secondary">
              {t("tak")}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    return (
      <div className="padding15vw">
        {dialog}
        {heading}
        {picker}
        <SimpleDialog
          open={this.state.simpleDialogOpen}
          content={this.state.simpleDialogContent}
          onClose={this.simpleDialogClose}
        />
        <OrderForm
          open={formOpen}
          editedOrder={this.state.editedOrder}
          close={this.handleFormClose}
          onCreate={() => this.onCreated(this.state.editedOrder.id)}
        />
        <Fab
          color="secondary"
          aria-label="dodaj"
          className={classes.addButton}
          onClick={this.handleFormOpen}
        >
          <AddIcon />
        </Fab>
        <div className={classes.root}>
          <OrdersTable
            orders={filteredOrders ? filteredOrders : []}
            startDate={this.state.dateRange.startDate}
            simpleDialogClose={this.simpleDialogClose}
            simpleDialogOpen={data => this.simpleDialogOpen(data)}
            editOrder={data => this.openEditHandler(data)}
            refreshOrders={this.refresh}
          />
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflow: "visible"
  },
  addButton: {
    position: "fixed",
    top: "68px",
    right: "20px",
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      top: "auto",
      bottom: "20px"
    }
  },
  roleInput: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  relative: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  dialog: {
    minWidth: "60vw"
  },
  overflow: {
    overflow: "visible"
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  date: {
    cursor: "pointer",
    display: "flex",
    padding: ".3em",
    borderRadius: "2px",
    alignItems: "center",
    marginLeft: "31px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
});

export default withSnackbar(withStyles(styles)(withTranslation("translations")(Orders)));
