import "react-app-polyfill/ie9";
import "core-js/fn/array/find";
import "core-js/fn/array/includes";
import "core-js/fn/number/is-nan";

import "./polyfills.js";

import React from "react";
import ReactDOM from "react-dom";

import { ErrorBoundary } from "./bugsnag";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import i18next from "i18next";
import moment from "moment";
import "moment-timezone";

import "typeface-roboto";
import "./index.css";
import axios from "axios";
import Main from "./Main";
import { PrivateRoute } from "./components/PrivateRoute";
import Login from "./components/login/Login";
import PassReset from "./components/PassReset/PassReset";
import PassResetConfirmation from "./components/PassReset/PassResetConfirmation";
import registerServiceWorker from "./registerServiceWorker";
import { Switch, Route, HashRouter } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";

import "moment/locale/pl";

if (i18next.languages[0] === "pl-PL") {
  moment.locale("pl");
} else {
  moment.locale("en");
}

moment.tz.setDefault("Europe/Warsaw");

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:3000/api/";
} else {
  axios.defaults.baseURL = "/api/";
}

axios.defaults.baseURL = "https://fly.400.pl/api/";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      sessionStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const token = sessionStorage.getItem("token");

if (token) {
  axios.defaults.headers.common["Authorization"] = token;
}

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink,
  },
  typography: {
    useNextVariants: true,
  },
});

ReactDOM.render(
  <ErrorBoundary>
    <I18nextProvider i18n={i18n}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <HashRouter>
          <MuiThemeProvider theme={theme}>
            <Switch>
              <Route exact path="/login" component={Login}></Route>
              <Route exact path="/password-reset" component={PassReset}></Route>
              <Route
                exact
                path="/password-reset/:token"
                component={PassResetConfirmation}
              ></Route>
              <Route
                exact
                path="/logout"
                render={(props) => <Login {...props} logout={true} />}
              ></Route>
              <PrivateRoute path="/" component={Main}></PrivateRoute>
            </Switch>
          </MuiThemeProvider>
        </HashRouter>
      </SnackbarProvider>
    </I18nextProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
registerServiceWorker();
