// bugsnag.js
import React from "react";
import bugsnag from "bugsnag-js";
import createPlugin from "bugsnag-react";

const bugsnagClient = bugsnag({
  apiKey: "b2c4bfd9d8c84d49e09e482bc034576d",
  beforeSend: report => {
    let user = { ...JSON.parse(sessionStorage.getItem("user")) };
    if (user && user.login) {
      report.user = { login: user.login, role: user.role };
    }
  }
});
const ErrorBoundary = bugsnagClient.use(createPlugin(React));

export { bugsnagClient, ErrorBoundary };
