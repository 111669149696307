import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

let AddressRow = (props) => {
  const { data } = props;

  return (
    <TableRow
      hover
      key={data.id}
      >
      <TableCell component="th" scope="row">
        {data.name}
      </TableCell>
      <TableCell>
        {data.address.address}
      </TableCell>
      <TableCell>
        <IconButton aria-label="Delete" style={{float: 'right'}} onClick={props.onDelete}>
            <DeleteIcon />
        </IconButton>
        <IconButton style={{float: 'right'}} aria-label="Edit" onClick={props.onEdit}>
          <CreateIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}


export default AddressRow;
