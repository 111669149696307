import React, { Component } from "react";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import axios from "axios";
import _ from "lodash";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Hidden from "@material-ui/core/Hidden";
import Icon from "@material-ui/core/Icon";
import IconSettings from "@material-ui/icons/Settings";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withSnackbar } from "notistack";

import AddressInput from "../AddressInput";
import DateInput from "../DateInput";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class OrderForm extends Component {
  constructor(props) {
    super(props);

    const user = JSON.parse(sessionStorage.getItem("user"));

    let defaultCC = user.default_cc ? user.default_cc : "";
    let ccOptions = [];

    if (defaultCC.includes(",")) {
      let options = defaultCC.split(",");
      options.forEach(element => {
        ccOptions.push(element.replace(/\s/g, ''));
      });
      defaultCC = options[0].replace(/\s/g, '');
    }

    this.state = {
      busy: false,
      newOrder: {
        when: "",
        extraWhen: [],
        from: { address: "", city: "", lat: "", lng: "" },
        through: [],
        to: { address: "", city: "", lat: "", lng: "" },
        client: {
          count: 1,
          name: "",
          tel: ""
        },
        paymentMaker: user.canCashless ? "company" : "client",
        costCenter: defaultCC,
        notes: "",
        companyInternalNotes: "",
        flightNumber: ""
      },
      addresses: [],
      ccOptions: ccOptions,
      user: user
    };
  }

  componentDidMount() {
    axios.get(`addresses`).then(res => {
      const addresses = res.data.addresses;
      this.setState({ addresses });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open) {
      return;
    }

    let order = {};
    let editedOrder = this.props.editedOrder;

    if (
      editedOrder &&
      editedOrder.id &&
      editedOrder.id !== this.state.newOrder.id
    ) {
      order = {
        id: editedOrder.id,
        when: editedOrder.when,
        extraWhen: [],
        from: editedOrder.from,
        through: editedOrder.through,
        to: editedOrder.to,
        client: editedOrder.client,
        paymentMaker: editedOrder.paymentMaker,
        costCenter: editedOrder.costCenter,
        notes: editedOrder.notes,
        companyInternalNotes: editedOrder.companyInternalNotes,
        flightNumber: editedOrder.flightNumber
      };

      this.setState({ newOrder: order });
    }
  }

  setDefaultOrder = () => {
    let defaultCC = this.state.user.default_cc ? this.state.user.default_cc : "";
    let ccOptions = [];

    if (defaultCC.includes(",")) {
      let options = defaultCC.split(",");
      options.forEach(element => {
        ccOptions.push(element.replace(/\s/g, ''));
      });
      defaultCC = options[0].replace(/\s/g, '');
    }

    this.setState({
      ccOptions: ccOptions,
      newOrder: {
        when: "",
        extraWhen: [],
        from: { address: "", city: "", lat: "", lng: "" },
        through: [],
        to: { address: "", city: "", lat: "", lng: "" },
        client: {
          count: 1,
          name: "",
          tel: ""
        },
        paymentMaker: this.state.user.canCashless ? "company" : "client",
        costCenter: defaultCC,
        notes: "",
        companyInternalNotes: "",
        flightNumber: ""
      }
    });
  };

  handleInputChange = (e, a) => {
    let { name, value } = e.target;
    let newOrder = this.state.newOrder;

    // reset costCenter if paymentMaker changed to client
    if (name === "paymentMaker" && value === "client") {
      newOrder.costCenter = "";
    }

    newOrder[name] = value;
    this.setState({ newOrder });
  };

  handleCCSelectChange = event => {
    let newOrder = this.state.newOrder;

    newOrder.costCenter = event.target.value;
    this.setState({ newOrder });
  };

  handleClientChange = (e, a) => {
    let { name, value } = e.target;
    let newOrder = this.state.newOrder;

    newOrder.client[name] = value;
    this.setState({ newOrder });
  };

  handleFromChange = address => {
    let newOrder = this.state.newOrder;

    newOrder.from = address;
    this.setState({ newOrder });
  };

  handleToChange = address => {
    let newOrder = this.state.newOrder;

    newOrder.to = address;
    this.setState({ newOrder });
  };

  addThroughPoint = () => {
    let newOrder = this.state.newOrder;

    newOrder.through = [
      ...newOrder.through,
      { address: "", city: "", lat: "", lng: "" }
    ];
    this.setState({ newOrder });
  };

  handleThroughChange = (address, index) => {
    let newOrder = this.state.newOrder;

    newOrder.through[index] = address;
    this.setState({ newOrder });
  };

  removeThroughPoint = index => {
    let newOrder = this.state.newOrder;

    newOrder.through.splice(index, 1);
    this.setState({ newOrder });
  };

  addExtraDate = () => {
    let newOrder = this.state.newOrder;
    let newDate = newOrder.when;

    if (newOrder.extraWhen.length) {
      newDate = newOrder.extraWhen[newOrder.extraWhen.length - 1];
    }

    newOrder.extraWhen.push(newDate);
    this.setState({ newOrder });
  };

  removeExtraDate = index => {
    let newOrder = this.state.newOrder;

    newOrder.extraWhen.splice(index, 1);
    this.setState({ newOrder });
  };

  handleDateChange = (date, fieldName, index = false) => {
    let newOrder = this.state.newOrder;
    if (index === false) {
      newOrder[fieldName] = date;
    } else {
      newOrder[fieldName][index] = date;
    }

    this.setState({ newOrder });
  };

  close = () => {
    this.setDefaultOrder();
    this.setState({ busy: false });
    this.props.close();
  };

  save = () => {
    let order = { ...this.state.newOrder };
    let removedThrough = _.remove(order.through, el => !el.city);

    if (!this.validate(order)) {
      this.props.enqueueSnackbar("Wypełnij wymagane pola", {
        variant: "error"
      });
      return false;
    }

    if (removedThrough.length) {
      this.setState({ newOrder: order });
    }

    if (this.state.newOrder.id) {
      this.update();
    } else {
      this.create();
    }
  };

  create = () => {
    let order = { ...this.state.newOrder };

    this.setState({ busy: true });
    axios
      .post(`orders`, order)
      .then(res => {
        this.close();
        this.props.onCreate();
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Skontaktuj się z nami telefonicznie";

        this.props.enqueueSnackbar(error, { variant: "error" });
        this.setState({ busy: false });
      });
  };

  update = () => {
    this.setState({ busy: true });
    let order = { ...this.state.newOrder };

    axios
      .put("orders/" + order.id, order)
      .then(response => {
        this.close();
        this.props.onCreate();
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Skontaktuj się z nami telefonicznie";

        this.props.enqueueSnackbar(error, { variant: "error" });
        this.setState({ busy: false });
      });
  };

  validate = newOrder => {
    if (newOrder.paymentMaker === "company" && !newOrder.costCenter) {
      return false;
    }
    if (
      !newOrder.when ||
      !newOrder.from.address ||
      !newOrder.to.address ||
      !newOrder.client.name ||
      !newOrder.client.tel
    )
      return false;
    return true;
  };

  addToAddresses = addres => {
    let addresses = this.state.addresses;

    addresses.push(addres);
    this.setState({ addresses });
  };

  render() {
    const { t } = this.props;
    const { classes, open } = this.props;
    const { busy, newOrder, addresses } = this.state;

    const validated = this.validate(newOrder);

    return (
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-add"
        fullScreen
        TransitionComponent={Transition}
      >
        <Grid container spacing={16}>
          <Grid item md={7}>
            <ValidatorForm ref="form" onSubmit={this.save}>
              <DialogContent className={classes.overflow}>
                <div className={classes.dateContainer}>
                  <Typography variant="subtitle1" gutterBottom>
                    {t("kiedy")}
                  </Typography>
                  <DateInput
                    onChange={date => this.handleDateChange(date, "when")}
                    selectedDate={newOrder.when}
                    inputClass={newOrder.when ? "" : "inputEmpty"}
                  />
                  {newOrder.extraWhen.map((date, index) => (
                    <div className={classes.flexRow} key={index}>
                      <DateInput
                        onChange={date =>
                          this.handleDateChange(date, "extraWhen", index)
                        }
                        selectedDate={date}
                      />
                      <Button
                        color="secondary"
                        size="small"
                        onClick={() => this.removeExtraDate(index)}
                      >
                        <Icon>delete</Icon>
                      </Button>
                    </div>
                  ))}
                  {newOrder.id ? null : (
                    <Tooltip title="Zlecenie cykliczne" placement="right">
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        className={classes.throughButton}
                        onClick={this.addExtraDate}
                      >
                        <AddIcon />
                      </Button>
                    </Tooltip>
                  )}
                </div>
                <div className={classes.tripContainer}>
                  <Typography variant="subtitle1" gutterBottom>
                    {t("trasa")}
                  </Typography>
                  <AddressInput
                    onSelect={this.handleFromChange}
                    label={t("skad")}
                    selectedAddress={newOrder.from.address}
                    selectedAddressObj={newOrder.from}
                    inputClass={newOrder.from.address ? "" : "inputEmpty"}
                    addToAddressesList={a => this.addToAddresses(a)}
                  />
                  {newOrder.through.map((address, index) => (
                    <div className={classes.flexRow} key={index}>
                      <AddressInput
                        onSelect={address =>
                          this.handleThroughChange(address, index)
                        }
                        label={t("przez")}
                        selectedAddress={address.address}
                        selectedAddressObj={address}
                        addToAddressesList={a => this.addToAddresses(a)}
                      />
                      <Tooltip title="Usuń punkt trasy" placement="top">
                        <Button
                          color="secondary"
                          size="small"
                          onClick={() => this.removeThroughPoint(index)}
                        >
                          <Icon>delete</Icon>
                        </Button>
                      </Tooltip>
                    </div>
                  ))}
                  <Tooltip title="Dodaj adres pośredni" placement="right">
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      className={classes.throughButton}
                      onClick={this.addThroughPoint}
                    >
                      <AddIcon />
                    </Button>
                  </Tooltip>
                  <AddressInput
                    onSelect={this.handleToChange}
                    label={t("dokad")}
                    selectedAddress={newOrder.to.address}
                    selectedAddressObj={newOrder.to}
                    addToAddressesList={a => this.addToAddresses(a)}
                    inputClass={newOrder.to.address ? "" : "inputEmpty"}
                  />
                </div>
                <Grid
                  container
                  spacing={32}
                  className={classes.othersContainer}
                >
                  <Grid item md={6}>
                    <TextValidator
                      margin="dense"
                      label={t("iloscosob")}
                      name="count"
                      type="number"
                      inputProps={{ min: "1", step: "1" }}
                      onChange={this.handleClientChange}
                      fullWidth
                      value={newOrder.client.count ? newOrder.client.count : 1}
                      validators={["required", "minNumber:1"]}
                      errorMessages={[
                        "To pole jest wymagane",
                        "Podaj liczbę pasażerów"
                      ]}
                    />
                    <TextValidator
                      margin="dense"
                      label={t("imienazwiskopasazera")}
                      name="name"
                      type="text"
                      onChange={this.handleClientChange}
                      fullWidth
                      value={newOrder.client.name ? newOrder.client.name : ""}
                      className={newOrder.client.name ? "" : "inputEmpty"}
                    />
                    <TextValidator
                      margin="dense"
                      label={t("telkontaktowy")}
                      name="tel"
                      type="text"
                      onChange={this.handleClientChange}
                      fullWidth
                      className={newOrder.client.tel ? "" : "inputEmpty"}
                      value={newOrder.client.tel ? newOrder.client.tel : ""}
                    />
                    <FormHelperText>
                      {t("rozdzielprzecinkiem")}
                    </FormHelperText>
                    <Grid container spacing={8}>
                      <Grid item sm={6}>
                        <TextValidator
                          margin="dense"
                          label={t("nrlotu")}
                          name="flightNumber"
                          type="text"
                          onChange={this.handleInputChange}
                          fullWidth
                          value={
                            newOrder.flightNumber ? newOrder.flightNumber : ""
                          }
                        />
                      </Grid>
                      <Grid item sm={6} style={{ display: "flex" }}>
                        {newOrder.flightNumber &&
                          newOrder.flightNumber.length > 3 ? (
                            <Button
                              variant="outlined"
                              color="secondary"
                              style={{ alignSelf: "flex-end" }}
                              target="_blank"
                              size="small"
                              href={
                                "https://www.google.pl/search?q=" +
                                newOrder.flightNumber
                              }
                            >
                              Sprawdź
                              <Icon className={classes.rightIcon}>flight</Icon>
                            </Button>
                          ) : null}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={6}>
                    <FormControl component="fieldset" margin="dense">
                      <FormLabel component="legend">{t("ktoplaci")}</FormLabel>
                      <RadioGroup
                        aria-label="payment maker"
                        name="paymentMaker"
                        value={newOrder.paymentMaker}
                        onChange={this.handleInputChange}
                      >
                        <FormControlLabel
                          value="client"
                          control={<Radio />}
                          label={t("pasazer")}
                        />
                        <FormHelperText
                          style={{ marginTop: "-10px", marginLeft: "35px" }}
                        >
                          {t("platnoscukierowcy")}
                        </FormHelperText>
                        <FormControlLabel
                          value="company"
                          disabled={!this.state.user.canCashless}
                          control={<Radio />}
                          label={t("firma")}
                        />
                        <FormHelperText
                          style={{ marginTop: "-10px", marginLeft: "35px" }}
                        >
                          {t("przejazdbezgotowkowy")}
                        </FormHelperText>
                      </RadioGroup>
                    </FormControl>
                    {newOrder.paymentMaker === "client" ? null :

                      this.state.ccOptions.length ? (
                        <React.Fragment>
                          <FormLabel component="legend" style={{ marginTop: "1em" }}>{t("ccmpkkarta")}</FormLabel>
                          <Select
                            value={this.state.newOrder.costCenter}
                            onChange={this.handleCCSelectChange}
                            fullWidth
                            label={t("ccmpkkarta")}
                            inputProps={{
                              name: "costCenter",
                              id: "costCenter"
                            }}
                          >
                            {this.state.ccOptions.map((option, index) => (
                              <MenuItem value={option} key={"ccOption" + index}>{option}</MenuItem>
                            ))}
                          </Select>
                        </React.Fragment>
                      ) : (
                          <TextValidator
                            margin="dense"
                            label={t("ccmpkkarta")}
                            name="costCenter"
                            type="text"
                            onChange={this.handleInputChange}
                            fullWidth
                            value={newOrder.costCenter ? newOrder.costCenter : ""}
                            className={newOrder.costCenter ? "" : "inputEmpty"}
                            disabled={newOrder.paymentMaker === "client"}
                          />
                        )


                    }
                  </Grid>
                </Grid>
                <TextField
                  label={t("uwagilong")}
                  multiline
                  rows="4"
                  name="notes"
                  onChange={this.handleInputChange}
                  value={newOrder.notes ? newOrder.notes : ""}
                  margin="normal"
                  variant="outlined"
                  fullWidth
                />
                {!newOrder.id ? (
                  <TextField
                    label={t("notatkilong")}
                    multiline
                    rows="4"
                    name="companyInternalNotes"
                    onChange={this.handleInputChange}
                    value={
                      newOrder.companyInternalNotes
                        ? newOrder.companyInternalNotes
                        : ""
                    }
                    margin="normal"
                    variant="outlined"
                    fullWidth
                  />
                ) : null}
              </DialogContent>
              <DialogActions style={{ marginBottom: "3rem" }}>
                <Button onClick={this.close} color="primary">
                  {t("anuluj")}
                </Button>
                <div className={classes.relative}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={busy || !validated}
                  >
                    {newOrder.id ? t("zapisz") : t("zamow")}
                  </Button>
                  {busy && (
                    <CircularProgress
                      size={24}
                      color="secondary"
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </DialogActions>
            </ValidatorForm>
          </Grid>
          <Hidden smDown>
            <Grid item md={5}>
              <DialogContent>
                <Grid container justify="center" spacing={16}>
                  {addresses.map(address => (
                    <Grid key={"address_" + address.id} item xs={6}>
                      <Card
                        className={classes.addressCard}
                        draggable
                        onDragStart={e => {
                          e.dataTransfer.setData(
                            "text",
                            JSON.stringify({
                              address: address.address.address,
                              city: address.address.city,
                              lat: address.address.lat,
                              lng: address.address.lng
                            })
                          );
                        }}
                      >
                        <CardContent>
                          <Typography variant="h5" component="h2">
                            {address.name}
                          </Typography>
                          <Typography component="p">
                            {address.address.address}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}

                  {!addresses.length ? (
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="h2">
                          {t("zdefiniujadresy")}
                        </Typography>
                        <ul>
                          <li>
                            {t("kliknijwsettings")}{" "}
                            <IconSettings />
                          </li>
                          <li>
                           {t("wybierzadresy")}
                          </li>
                          <li>{t("zamawiajszybciej")}</li>
                        </ul>
                      </CardContent>
                    </Card>
                  ) : null}
                </Grid>
              </DialogContent>
            </Grid>
          </Hidden>
        </Grid>
      </Dialog>
    );
  }
}

const styles = theme => ({
  relative: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  overflow: {
    overflow: "visible"
  },
  throughButton: {
    margin: theme.spacing.unit,
    marginLeft: 0,
    padding: 0
  },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  tripContainer: {
    margin: "2em 0",
    padding: "2em 1em",
    paddingTop: 0,
    borderLeft: "4px solid #8bc34a"
  },
  dateContainer: {
    margin: "2em 0",
    padding: "2em 1em",
    paddingTop: 0,
    borderLeft: "4px solid #ab003c"
  },
  othersContainer: {
    margin: "2em 0",
    padding: "2em 1em",
    paddingTop: 0,
    borderLeft: "4px solid #2196f3"
  },
  addressCard: {
    cursor: "move",
    backgroundColor: "#a2cf6e"
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  }
});

export default withSnackbar(
  withStyles(styles)(withTranslation("translations")(OrderForm))
);
