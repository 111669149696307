import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import scriptLoader from "react-async-script-loader";
import config from "../config";
import _ from "lodash";
import axios from "axios";
import { withTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { withStyles } from "@material-ui/core/styles";

import SimpleDialog from "./SimpleDialog";
class AddressInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      addressObj: {},
      addressName: "",
      ready: false,
      dragging: false,
      error: false,
      locationBias: "",
      simpleDialogOpen: false,
    };
  }

  componentWillReceiveProps({
    isScriptLoaded,
    isScriptLoadSucceed,
    selectedAddress,
    selectedAddressObj,
  }) {
    if (isScriptLoaded && !this.props.isScriptLoaded) {
      // load finished
      if (isScriptLoadSucceed) {
        this.setState({
          ready: true,
          locationBias: window.google
            ? new window.google.maps.LatLng(51.7592, 19.456)
            : null,
        });
      }
    }

    if (selectedAddress)
      this.setState({
        address: selectedAddress,
        addressObj: selectedAddressObj,
      });
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ ready: true });
    }
  }

  handleChange = (address) => {
   if (address.includes("}")) return false;
      this.setState({ address, addressObj: {} });
      this.props.onSelect({});
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        let city = _.find(results[0].address_components, (component) => {
          return component.types.find((type) => {
            return type === "locality";
          });
        });

        // Barry case when locality is "Street"
        if (city && city.long_name === "Street") {
          city = null;
        }

        if (!city) {
          city = _.find(results[0].address_components, (component) => {
            return component.types.find((type) => {
              return type === "administrative_area_level_2";
            });
          });
        }

        if (!city) {
          city = _.find(results[0].address_components, (component) => {
            return component.types.find((type) => {
              return type === "sublocality";
            });
          });
        }

        if (!city) {
          city = _.find(results[0].address_components, (component) => {
            return component.types.find((type) => {
              return type === "administrative_area_level_1";
            });
          });
        }

        if (!city) {
          city = { long_name: "" };
        }

        let addressObj = {
          address: address,
          city: city.long_name,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        };
        this.setState({
          address: address,
          error: false,
          addressObj: addressObj,
        });
        this.props.onSelect(addressObj);
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: true });
      });
  };

  simpleDialogClose = () => {
    this.setState({ simpleDialogOpen: false, addressName: "" });
  };

  simpleDialogOpen = () => {
    this.setState({ simpleDialogOpen: true });
  };

  handleAddressNameChange = (e) => {
    let { value } = e.target;
    this.setState({ addressName: value });
  };

  addToFavouriteAddresses = () => {
    let address = {
      name: this.state.addressName,
      address: { ...this.state.addressObj },
    };

    axios.post(`addresses`, address).then((res) => {
      this.simpleDialogClose();
      address.id = res.data.address.id;
      this.props.addToAddressesList(address);
    });
  };

  onDragOver = (e) => {
    this.dragging(true);
    e.stopPropagation();
    e.preventDefault();
  };

  onDrop = (e) => {
    let data = e.dataTransfer.getData("text");
    data = JSON.parse(data);
   

    if (data.address && data.city) {
      this.setState({
        address: data.address,
        dragging: false,
        addressObj: data,
      });
     this.props.onSelect(data);
    }
  };

  dragging = (dragging) => {
    if (this.state.draggin !== dragging) this.setState({ dragging });
  };

  render() {
    let input = "";
    let { classes, label, t } = this.props;
    let error = false;

    if (!label) label = t("adres");

    if (this.state.address && !this.state.addressObj.city) {
      label = t("wybierzadres");
      error = true;
    }

    let inputStyle = {};

    if (this.state.error) {
      inputStyle = {
        backgroundColor: "red",
        color: "white",
      };
    }

    if (this.state.dragging) {
      inputStyle = {
        backgroundColor: "#a2cf6e",
      };
    }



    if (this.state.ready) {
      input = (
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          highlightFirstSuggestion={true}
          debounce={500}
          shouldFetchSuggestions={this.state.address.length > 2}
          searchOptions={{ location: this.state.locationBias, radius: 400000 }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div className={classes.container}>
              <TextField
                error={error ? true : false}
                label={label}
                value={this.state.address}
                margin="dense"
                fullWidth
                {...getInputProps({
                  placeholder: "Szukaj adresu ...",
                  className: "location-search-input",
                })}
                className={this.props.inputClass}
                onDragOver={this.onDragOver}
                onDrop={this.onDrop}
                onDragLeave={() => this.dragging(false)}
                style={inputStyle}
              />
              {this.state.addressObj.city && this.props.addToAddressesList && (
                <Tooltip title="Dodaj adres do ulubionych" placement="top">
                  <Button
                    size="small"
                    color="primary"
                    onClick={this.simpleDialogOpen}
                  >
                    <Icon>add_box</Icon>
                  </Button>
                </Tooltip>
              )}
              <Paper square className={classes.paper}>
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? "suggestion-item--active"
                    : "suggestion-item";
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <MenuItem
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      {suggestion.description}
                    </MenuItem>
                  );
                })}
              </Paper>
            </div>
          )}
        </PlacesAutocomplete>
      );
    }

    let dialogContent = (
      <React.Fragment>
        <DialogContent>
          <TextField
            label="Podaj nazwę"
            value={this.state.addressName}
            margin="dense"
            fullWidth
            onChange={this.handleAddressNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.simpleDialogClose}>
            Anuluj
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.addToFavouriteAddresses}
          >
            Dodaj
          </Button>
        </DialogActions>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <SimpleDialog
          open={this.state.simpleDialogOpen}
          content={dialogContent}
          onClose={this.simpleDialogClose}
        />
        {input}
      </React.Fragment>
    );
  }
}

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: "relative",
    overflow: "visible",
    display: "flex",
  },
  paper: {
    position: "absolute",
    zIndex: 9999999999,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    top: "50px",
  },
});

export default scriptLoader(
  "https://maps.googleapis.com/maps/api/js?key=" +
    config.gmaps_api_key +
    "&libraries=places"
)(withStyles(styles)(withTranslation("translations")(AddressInput)));
