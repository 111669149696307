import React, { Component } from "react";
import { Route, Link } from "react-router-dom";
import logo from "./images/logoWhite.svg";
import { withTranslation } from "react-i18next";

import Users from "./components/users/Users";
import Addresses from "./components/addresses/Addresses";
import Orders from "./components/orders/Orders";
import PasswordChange from "./components/users/PasswordChange";

import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import IconSettings from "@material-ui/icons/Settings";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";

class Main extends Component {
  constructor(props) {
    super();
    document.title = `Fly`;
    this.state = {
      user: { ...JSON.parse(sessionStorage.getItem("user")) },
      anchorEl: null,
    };
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, location, t } = this.props;
    const { anchorEl, user } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar variant="dense">
            <Link to="/" className={classes.logoLink}>
              {location.pathname !== "/" ? (
                <Tooltip title="Wróć" placement="right">
                  <Icon className={classes.backArrow}>arrow_back</Icon>
                </Tooltip>
              ) : null}
              <img
                src={logo}
                alt="fly"
                width="32"
                height="32"
                className={classes.menuButton}
              />
            </Link>
            <div className={classes.flex}></div>
            <IconButton
              aria-owns={open ? "menu-appbar" : null}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
            >
              <IconSettings />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={this.handleClose}
            >
              <Link
                to="/addresses"
                className={classes.link}
                key="menuAddresses"
              >
                <MenuItem onClick={this.handleClose}>{t("ulubioneadresy")}</MenuItem>
              </Link>
              {user.role === "clientManager" && user.canCreateUsers ? (
                <Link to="/users" className={classes.link} key="menuUsers">
                  <MenuItem onClick={this.handleClose}>{t("uzytkownicy")}</MenuItem>
                </Link>
              ) : null}
              {user.role != "guest" && (
                <Link to="/password-change" className={classes.link}>
                  <MenuItem onClick={this.handleClose}>{t("zmienhaslo")}</MenuItem>
                </Link>
              )}
              <Divider />
              <Link to="/logout" className={classes.link}>
                <MenuItem onClick={this.handleClose}>{t("wyloguj")}</MenuItem>
              </Link>
            </Menu>
          </Toolbar>
        </AppBar>
        <div className={classes.contentPaddingTop}>
          <Route exact path="/" component={Orders}></Route>
          <Route exact path="/addresses" component={Addresses}></Route>
          {user.role === "clientManager" && user.canCreateUsers ? (
            <Route exact path="/users" component={Users}></Route>
          ) : null}

          <Route
            exact
            path="/password-change"
            component={PasswordChange}
          ></Route>
        </div>
      </div>
    );
  }
}

const styles = {
  flex: {
    flexGrow: 1,
  },
  link: {
    textDecoration: "none",
    display: "block",
  },
  logoLink: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    lineHeight: "1em",
  },
  contentPaddingTop: {
    paddingTop: "70px",
  },
  backArrow: {
    color: "#fff",
    marginRight: "1em",
  },
};
export default withStyles(styles)(withTranslation("translations")(Main));
