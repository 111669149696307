import React from 'react';
import { withTranslation } from "react-i18next";
import Chip from '@material-ui/core/Chip';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import CreateIcon from '@material-ui/icons/Create';

let UserRow = (props) => {
  const { data, t } = props;

  let role = data.role === 'clientManager' ? 'Manager' : data.role === 'client' ? t("klient") : data.role === 'guest' ? t("gosc") : 'Kierowca';
  let isActive = data.isActive ? (<Chip color="primary" label={t("aktywny")} style={{margin: '8px 0'}}/>) : (<Chip color="secondary" label={t("nieaktywny")} style={{margin: '8px 0'}}/>);

  return (
    <TableRow
      hover
      key={data.id}
      >
      <TableCell component="th" scope="row">
        {data.name}
      </TableCell>
      <TableCell>{data.login}</TableCell>
      <TableCell>{data.email}</TableCell>
      <TableCell>{data.phone}</TableCell>
      <TableCell>{role}</TableCell>
      <TableCell>
        {isActive}
        <IconButton style={{float: 'right'}} aria-label="Edit" onClick={props.onEdit}>
          <CreateIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}


export default withTranslation("translations")(UserRow);
