import React, { PureComponent } from "react";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
//import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MuiPickersUtilsProvider from "material-ui-pickers/MuiPickersUtilsProvider";
import { InlineDateTimePicker } from "material-ui-pickers/DateTimePicker";

import moment from "moment";
import { withSnackbar } from "notistack";

class DateInput extends PureComponent {
  state = {
    selectedDate: this.props.selectedDate
      ? this.props.selectedDate
      : moment(new Date())
          .add(30, "m")
          .toDate(),
    error: false
  };

  handleDateChange = date => {
    let minimum = moment();

    if (moment(date).isAfter(minimum)) {
      this.setState({ selectedDate: date });
      this.props.onChange(date.format());
    } else {
      this.props.enqueueSnackbar("Wybierz datę i godzinę z przyszłości", {
        variant: "error"
      });
    }
  };

  render() {
    const { selectedDate, error } = this.state;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <InlineDateTimePicker
          className={this.props.inputClass}
          ampm={false}
          error={error}
          value={selectedDate}
          onChange={this.handleDateChange}
          fullWidth
          disablePast={true}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default withSnackbar(DateInput);
