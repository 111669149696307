import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";

import logo from "./../../images/logo.svg";
import bgImg from "./../../images/loginBg.jpg";
import axios from "axios";

class PassResetConfirmation extends Component {
  constructor(props) {
    super(props);
    document.title = `Fly`;
    this.state = {
      status: "loading"
    };

  }

  componentDidMount() {
    if (!this.props.match.params.token) {
      this.setState({ status: 'failure' });
    } else {
      this.reset();
    }
  }

  reset = () => {
    axios
      .post("users/resetPassword", {
        reset_token: this.props.match.params.token
      })
      .then(response => {
        this.setState({ status: 'success' });
        window.setTimeout(() => { this.props.history.push("/"); }, 10000);
      })
      .catch(err => {
        this.setState({ status: 'failure' });
      });
  };

  render() {
    const { t } = this.props;
    let classes = this.props.classes;
    return (
      <div className={classes.bg}>
        <CssBaseline />
        <div className={classes.layout}>
          <Paper className={classes.paper}>
            <img className={classes.avatar} src={logo} alt="fly" />
            <br /><br />
            {this.state.status === 'loading' && <p>{t("resetting")}</p>}
            {this.state.status === 'success' && <p>{t("passresetconfirmation")}</p>}
            {this.state.status === 'failure' && <p>{t("passresetfailure")}</p>}

          </Paper>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  bg: {
    backgroundImage: `url(${bgImg})`,
    backgroundSize: "cover",
    width: "100vw",
    height: "100vh",
    position: "absolute"
  },
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    width: "48px",
    height: "48px"
  }
});

export default withStyles(styles)(withTranslation("translations")(PassResetConfirmation));
