import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: {
        godzina: "Hour",
        trasa: "Route",
        pasazer: "Passenger",
        iloscosob: "Passengers count",
        kierowca: "Driver",
        kwotafirma: "To pay (company)",
        kwotapasazer: "To pay (passenger)",
        ccmpkkarta: "Cost Center",
        zamawiajacy: "Orderer",
        nrlotu: "Flight number",
        uwagi: "Notes",
        notatki: "Internal notes",
        kiedy: "When",
        skad: "From",
        dokad: "To",
        przez: "Through",
        imienazwiskopasazera: "Passenger name",
        telkontaktowy: "Phone number",
        ktoplaci: "Payment maker",
        firma: "Company",
        platnoscukierowcy: "To be payed in taxi. Cash or credit card",
        przejazdbezgotowkowy: "Cashless",
        uwagilong: "Notes for dispatcher and driver",
        notatkilong: "Internal notes (visible only within company)",
        anuluj: "Cancel",
        zamow: "Order",
        zapisz: "Save",
        rozdzielprzecinkiem: "Please separate phone numbers with comma",
        zaloguj: "Login",
        haslo: "Password",
        resetujhaslo: "Reset password",
        passresetconfirmation: "Your new password has been sent via email.",
        passresetlink: "Link to reset your password has been sent via email",
        wrongemail: "Wrong email",
        resetting: 'Resetting...',
        passresetfailure: 'Something went wrong. Try again later or contact us.',
        filtruj: "Filter",
        zastosuj: "Save",
        chceszanulowac: "Are you sure you want to cancel?",
        tak: "Yes",
        nie: "No",
        odswiez: "Refresh",
        edytuj: "Edit",
        anulujzamowienie: "Cancel order",
        kontakttelefonicznie: "In order to make changes please content us by phone",
        pobierz: "Download",
        zdefiniujadresy: "Add predefined places!",
        kliknijwsettings: "At the top right corner of the main page click",
        wybierzadresy: 'Pick "My Places"',
        zamawiajszybciej: "Order faster!",
         ulubioneadresy: "My Places",
         uzytkownicy: "Users",
         zmienhaslo: "Change password",
         wyloguj: "Logout",
         nazwa: "Name",
         adres: "Address",
         requiredfield: "This field is required",
         wybierzadres: "Pick from the list",
         aktywny: "Active",
         nieaktywny: "Inactive",
         telefon: "Phone",
         wiecejnizjedenadres: "Divide emails with comma",
         domyslnecc: "Default const center",
          domyslneccwytlumaczenie: "Cost center or card that will be default for given user.",
          potwierdzeniehasla: "Password Confirmation",
          gosc: "Guest",
        klient: "Client",
        typ: "Type",
         mozezarzadzac: "Can manage users",
        niemozezarzadzac: "Can NOT manage users",
        mozebezgotowka: "Can create cashless orders",
        niemozebezgotowka: "Can NOT create cashless orders",
      }
    },
    pl: {
      translations: {
        godzina: "Godzina",
        trasa: "Trasa",
        pasazer: "Pasażer",
        iloscosob: "Ilość osób",
        kierowca: "Kierowca",
        kwotafirma: "Kwota firma",
        kwotapasazer: "Kwota pasażer",
        ccmpkkarta: "CC/MPK/Karta",
        zamawiajacy: "Zamawiający",
        nrlotu: "nr lotu",
        uwagi: "Uwagi",
        notatki: "Notatki",
        kiedy: "Kiedy",
        skad: "Skąd",
        dokad: "Dokąd",
        przez: "Przez",
        imienazwiskopasazera: "Imie i Nazwisko pasażera",
        telkontaktowy: "Tel. kontaktowy",
        ktoplaci: "Kto płaci",
        firma: "Firma",
        platnoscukierowcy: "Płatność u kierowcy gotówką lub kartą",
        przejazdbezgotowkowy: "Przejazd bezgotówkowy",
        uwagilong: "Uwagi dla dyspozytora i kierowcy",
        notatkilong: "Notatki (widoczne wyłącznie wewnątrz firmy)",
        anuluj: "Anuluj",
        zamow: "Zamów",
        zapisz: "Zapisz",
        rozdzielprzecinkiem: "Prosimy oddzielić numery telefonów przecinkiem",
        zaloguj: "Zaloguj",
        haslo: "Hasło",
        resetujhaslo: "Resetuj hasło",
        passresetconfirmation: "Nowe hasło zostało wysłane emailem",
        passresetlink: "Link resetujący hasło został wysłany emailem",
        wrongemail: "Nie znaleziono takiego adresu email",
        resetting: 'Resetuję...',
        passresetfailure: 'Coś poszło nie tak. Spróbuj później lub skontaktuj się znami.',
        filtruj: "Filtruj",
        zastosuj: "Zastosuj",
        chceszanulowac: "Na pewno chcesz anulować zamówienie?",
        tak: "Tak",
        nie: "Nie",
        odswiez: "Odśwież",
        edytuj: "Edytuj",
        anulujzamowienie: "Anuluj zamówienie",
        kontakttelefonicznie: "Aby dokonać zmian skontaktuj się z nami telefonicznie",
        pobierz: "Pobierz",
        zdefinujadresy: "Zdefiniuj ulubione adresy!",
        kliknijwsettings: "Na stronie głównej w prawym, górnym rogu kliknij w",
        wybierzadresy: 'Wybierz "Ulubione Adresy"',
        zamawiajszybciej: "Zamawiaj szybciej",
        ulubioneadresy: "Ulubione Adresy",
        uzytkownicy: "Użytkownicy",
        zmienhaslo: "Zmień hasło",
        wyloguj:"wyloguj",
        nazwa: "Nazwa",
        adres: "Adres",
        requiredfield: "To pole jest wymagane",
        wybierzadres: "Wybierz adres z listy",
        aktywny: "Aktywny",
        nieaktywny: "Nieaktywny",
        telefon: "Telefon",
        wiecejnizjedenadres: "Możliwe jest podanie więcej niż jednego adresu. Adresy należy oddzielić przecinkiem.",
        domyslnecc: "Domyślne centrum kosztowe",
        domyslneccwytlumaczenie: "Centrum kosztowe lub karta, która domyślnie podstawi się w formularzu zamówienia dla danego użytkownika.",
        potwierdzeniehasla: "Potwierdzenie hasła",
        gosc: "Gość",
        klient: "Klient",
        typ: "Typ",
        mozezarzadzac: "Może zarządzać użytkownikami",
        niemozezarzadzac: "NIE może zarządzać użytkownikami",
         mozebezgotowka: "Może zamawiać na koszt firmy",
        niemozebezgotowka: "NIE może zamawiać na koszt firmy",
      }
    }
  },
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
