import React, { Component } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import _ from 'lodash';
import { withTranslation } from "react-i18next";
import Icon from "@material-ui/core/Icon";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";

import moment from "moment";

class OrderRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      note: this.props.data.companyInternalNotes,
      editingNote: false
    };
  }

  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  editNote = () => {
    this.setState({ editingNote: true });
  };

  closeNoteEditor = () => {
    this.setState({ editingNote: false });
  };

  handleNoteChange = (e, a) => {
    let { value } = e.target;

    this.setState({ note: value });
  };

  saveNote = () => {
    let note = this.state.note.trim();

    axios
      .put("orders/" + this.props.data.id + "/note", { note: note })
      .then(this.props.refreshOrders);

    this.setState({ editingNote: false });
  };

  cancel = () => {
    let newOrder = { ...this.props.data };
    newOrder.status = "cancelled";

    axios
      .delete("orders/" + newOrder.id)
      .then(response => {
        this.props.simpleDialogClose();
        this.handleMenuClose();
        this.props.refreshOrders();
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  getPDF = () => {
    let data = {
      order: this.props.data
    };
    axios({
      method: "post",
      responseType: "blob",
      url: `pdf/createorderconfirmation`,
      data: data
    })
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "potwierdzenie_fly.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Nie udało się wygenerwać pliku";

        this.props.enqueueSnackbar(error, { variant: "error" });
        this.setState({ busy: false });
      });
  };

  render() {
    let { data, classes, t } = this.props;
    const time = moment(data.when).format("HH:mm");

    const variants = {
      confirmed: {
        icon: "check_circle",
        color: "#a2cf6e"
      },
      other: {
        icon: "cancel",
        color: "#ab003c"
      }
    };

    const variant = data.confirmed ? variants.confirmed : variants.other;

    let panelClasses = ``;

    if (data.status === "cancelled") {
      panelClasses += ` ${classes.cancelled}`;
    }

    let driverName = data.driver ? data.driver.name : "";
    driverName +=
      data.driver && data.driver.phone ? " (" + data.driver.phone + ")" : "";

    let amountPassenger = "";
    let amountCompany = "";

    if (data.paymentMaker === "client") {
      amountPassenger = data.paymentAmount;
    } else {
      amountCompany = data.paymentAmount;
    }

    let cancelConfirmation = (
      <React.Fragment>
        <DialogContent>{t("chceszanulowac")}</DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.props.simpleDialogClose}>
            {t("nie")}
          </Button>
          <Button variant="contained" color="secondary" onClick={this.cancel}>
            {t("tak")}
          </Button>
        </DialogActions>
      </React.Fragment>
    );

    let toAddress = data.to && data.to.address ? data.to.address : "";
    let fromAddress = data.from && data.from.address ? data.from.address : "";

    let toCity = data.to && data.to.city ? data.to.city : "";
    let fromCity = data.from && data.from.city ? data.from.city : "";
    // let toAddress = data.to.address.substr(0, data.to.address.indexOf(","));
    // if (!toAddress) toAddress = data.to.address;

    return (
      <React.Fragment>
        <Dialog open={this.state.editingNote} onClose={this.closeNoteEditor}>
          <DialogContent>
            <TextField
              label="Notatki"
              multiline
              rows="4"
              name="notes"
              onChange={this.handleNoteChange}
              value={this.state.note ? this.state.note : ""}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.closeNoteEditor}>
              {t("anuluj")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={this.saveNote}
            >
              {t("zapisz")}
            </Button>
          </DialogActions>
        </Dialog>
        <TableRow key={data.identifier} className={panelClasses}>
          <TableCell align="center">{time}</TableCell>
          <TableCell align="center">
            <strong>{fromCity}</strong> {fromAddress}
            -&nbsp;
            {data.through.map(el => {
              if (_.isEmpty(el)) return null;
              return (
                <span key={el.address}>
                  <strong>{el.city}</strong> {el.address} -&nbsp;
                </span>
              );
            })}
            <strong>{toCity}</strong> {toAddress}
          </TableCell>
          <TableCell align="center">
            {data.client.name}{" "}
            {data.client.tel ? "(" + data.client.tel + ")" : null}
          </TableCell>
          <TableCell align="center">{data.client.count}</TableCell>
          <TableCell align="center">{driverName}</TableCell>
          <TableCell align="center">{data.driverNumber}</TableCell>
          <TableCell align="center">{amountCompany}</TableCell>
          <TableCell align="center">{amountPassenger}</TableCell>
          <TableCell align="center">{data.costCenter}</TableCell>
          <TableCell align="center">{data.orderer.name}</TableCell>
          <TableCell align="center">{data.flightNumber}</TableCell>
          <TableCell align="center">{data.notes}</TableCell>
          <TableCell
            align="center"
            className={classes.cursorPointer}
            onClick={this.editNote}
          >
            <Tooltip title="Edytuj" placement="top">
              <span>{data.companyInternalNotes}</span>
            </Tooltip>
          </TableCell>
          <TableCell align="center">
            <div className={classes.flex}>
              {variant ? (
                <div style={{ color: variant.color }}>
                  <Icon>{variant.icon}</Icon>
                </div>
              ) : null}

              {data.status !== "cancelled" && (
                <React.Fragment>
                  <IconButton onClick={this.handleMenuOpen}>
                    <Icon>more_vert</Icon>
                  </IconButton>
                  <Menu
                    id="order-actions-menu"
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleMenuClose}
                  >
                    {data.status !== "cancelled" && !data.DriverId ? (
                      [
                        <MenuItem
                          key={"e" + data.identifier}
                          onClick={() => {
                            this.props.editOrder(data);
                            this.handleMenuClose();
                          }}
                        >
                          {t("edytuj")}
                        </MenuItem>,
                        <Divider key={"d" + data.identifier} />,
                        <MenuItem
                          key={"a" + data.identifier}
                          onClick={() => {
                            this.props.simpleDialogOpen(cancelConfirmation);
                            this.handleMenuClose();
                          }}
                        >
                          {t("anulujzamowienie")}
                        </MenuItem>
                      ]
                    ) : (
                        <MenuItem key={"z" + data.identifier} disabled={true}>
                          {t("kontakttelefonicznie")}
                        </MenuItem>
                      )}
                    {data.confirmed && (
                      <MenuItem
                        onClick={() => {
                          this.getPDF();
                          this.handleMenuClose();
                        }}
                      >
                        {t("pobierz")} PDF
                      </MenuItem>
                    )}
                  </Menu>
                </React.Fragment>
              )}
            </div>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginBottom: "3px",
    display: "flex",
    flexDirection: "column"
  },
  flexGrow: {
    flexGrow: "1"
  },
  cancelled: {
    textDecoration: "line-through"
  },
  warning: {
    color: "#f50057",
    fontSize: "12px",
    textAlign: "right",
    padding: "1em"
  },
  cursorPointer: {
    cursor: "pointer"
  },
  greenText: {
    color: "#2e7d32",
    fontWeight: "bold"
  },
  flex: {
    display: "flex",
    alignItems: "center"
  },
  marker: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.7)",
    color: "#fff",
    padding: "6px",
    borderRadius: "3px"
  },
  label: {
    display: "block",
    fontSize: "12px"
  }
});

export default withSnackbar(withStyles(styles)(withTranslation("translations")(OrderRow)));
