import React, { Component } from "react";
import _ from "lodash";
import axios from "axios";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { withSnackbar } from "notistack";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import Fab from "@material-ui/core/Fab";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import UserRow from "./UserRow";

class Users extends Component {
  state = {
    formOpen: false,
    formBusy: false,
    changePass: false,
    newUser: {
      role: "client",
      login: "",
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
      isActive: true,
      phone: "",
      canCreateUsers: 0,
      canCashless: 1,
      default_cc: ""
    },
    users: []
  };

  componentDidMount() {
    axios.get(`users`).then(res => {
      const users = res.data.users;
      this.setState({ users: users });
    });

    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      if (value !== this.state.newUser.password) {
        return false;
      }
      return true;
    });
  }

  handleFormOpen = () => {
    this.setState({
      formOpen: true,
      changePass: false,
      newUser: {
        role: "client",
        login: "",
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        isActive: true,
        phone: "",
        canCreateUsers: 0,
        canCashless: 1,
        default_cc: ""
      }
    });
  };

  handleFormClose = () => {
    this.setState({ formOpen: false });
  };

  handleInputChange = (e, a) => {
    let { name, value } = e.target;
    let newUser = this.state.newUser;

    if (name === "login") {
      value = value.replace(/[^\w\s.]/gi, "").toLowerCase();
    }

    newUser[name] = value;
    this.setState({
      newUser: newUser
    });
  };

  handleRoleChange = event => {
    let newUser = this.state.newUser;

    newUser.role = event.target.value;
    this.setState({ newUser: newUser });
  };

  openEditHandler = data => {
    let newUser = { ...data };

    newUser.password = "";
    newUser.password_confirmation = "";

    this.setState({ newUser: newUser, formOpen: true, changePass: false });
  };

  handleActiveSwitchChange = event => {
    let data = this.state.newUser;

    data.isActive = event.target.checked;
    this.setState({ newUser: data });
  };

  handleCanCreateUsersSwitchChange = event => {
    let data = this.state.newUser;

    data.canCreateUsers = event.target.checked;
    this.setState({ newUser: data });
  };

   handleCanCashlessSwitchChange = event => {
    let data = this.state.newUser;

    data.canCashless = event.target.checked;
    this.setState({ newUser: data });
  };

  userSave = () => {
    if (this.state.newUser.id) {
      this.userUpdate();
    } else {
      this.userCreate();
    }
  };

  userUpdate = () => {
    this.setState({ formBusy: true });
    let user = { ...this.state.newUser };

    user.isActive = user.isActive ? 1 : 0;
    user.canCreateUsers = user.canCreateUsers ? 1 : 0;
    user.canCashless = user.canCashless ? 1 : 0;

    if (!user.password) {
      delete user.password;
      delete user.password_confirmation;
    }

    axios
      .put("users/" + user.id, user)
      .then(response => {
        let users = this.state.users;
        let newUser = response.data.user;
        let pos = users.map(e => e.id).indexOf(newUser.id);

        users[pos] = newUser;
        this.setState({ users: users, formBusy: false, formOpen: false });
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";
        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
        this.setState({ formBusy: false });
      });
  };

  userCreate = () => {
    let user = { ...this.state.newUser };

    delete user.password_confirmation;
    user.isActive = user.isActive ? 1 : 0;
    user.canCreateUsers = user.canCreateUsers ? 1 : 0;
    user.canCashless = user.canCashless ? 1 : 0;

    this.setState({ formBusy: true });
    axios
      .post(`users`, user)
      .then(res => {
        let users = this.state.users;

        users.push(res.data.user);
        this.setState({ users: users, formBusy: false, formOpen: false });
      })
      .catch(err => {
        this.setState({ formBusy: false });
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "";

        this.props.enqueueSnackbar("Błąd! " + error, { variant: "error" });
      });
  };

  changePass = () => {
    this.setState({ changePass: true });
  };

  render() {
    let { users, formBusy, newUser, changePass } = this.state;
    const { classes, t } = this.props;
    const isEdited = newUser.id ? true : false;

    if (isEdited) {
      newUser.isActive = newUser.isActive ? true : false;
    }

    users = _.sortBy(users, [
      function (o) {
        return !o.isActive;
      }
    ]);

    return (
      <div className="padding15vw">
        <Fab
          color="secondary"
          aria-label="dodaj"
          className={classes.addButton}
          onClick={this.handleFormOpen}
        >
          <AddIcon />
        </Fab>
        <Dialog
          open={this.state.formOpen}
          onClose={this.handleFormClose}
          scroll="body"
          aria-labelledby="form-dialog-add"
        >
          <ValidatorForm ref="form" onSubmit={this.userSave}>
            <DialogContent>
              <FormControlLabel
                control={
                  <Switch
                    checked={newUser.isActive}
                    onChange={this.handleActiveSwitchChange}
                    value="isActive"
                    color="primary"
                  />
                }
                label={newUser.isActive ? t("aktywny") : t("nieaktywny")}
              />
              <TextValidator
                autoFocus
                margin="dense"
                label="Login"
                name="login"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newUser.login}
                disabled={isEdited}
                validators={["required"]}
                errorMessages={["To pole jest wymagane"]}
              />

              <TextValidator
                margin="dense"
                label={t("name")}
                name="name"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newUser.name}
                validators={["required"]}
                errorMessages={["To pole jest wymagane"]}
              />

              <TextValidator
                margin="dense"
                label="Email"
                name="email"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newUser.email}
                validators={["required"]}
                errorMessages={["To pole jest wymagane"]}
              />

              <FormHelperText>
                {t("wiecejnizjedenadres")}
              </FormHelperText>

              <TextValidator
                margin="dense"
                label={t("telefon")}
                name="phone"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newUser.phone}
              />

              <TextValidator
                margin="dense"
                label={t("domyslnecc")}
                name="default_cc"
                type="text"
                onChange={this.handleInputChange}
                fullWidth
                value={newUser.default_cc}
              />

              <FormHelperText>
                {t("domyslneccwytlumaczenie")}
              </FormHelperText>

              {isEdited && !changePass ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.changePass}
                  style={{ marginTop: "2rem" }}
                >
                  {t("zmienhaslo")}
                </Button>
              ) : (
                  <React.Fragment>
                    <TextValidator
                      margin="dense"
                      label={t("haslo")}
                      name="password"
                      type="text"
                      onChange={this.handleInputChange}
                      fullWidth
                      value={newUser.password}
                      validators={isEdited ? [] : ["required"]}
                      errorMessages={["To pole jest wymagane"]}
                    />

                    <TextValidator
                      margin="dense"
                      label={t("potwierdzeniehasla")}
                      name="password_confirmation"
                      type="text"
                      onChange={this.handleInputChange}
                      fullWidth
                      value={newUser.password_confirmation}
                      validators={
                        isEdited
                          ? ["isPasswordMatch"]
                          : ["isPasswordMatch", "required"]
                      }
                      errorMessages={[
                        "Hasła muszą być takie same",
                        "To pole jest wymagane"
                      ]}
                    />
                  </React.Fragment>
                )}

              <Select
                className={classes.roleInput}
                value={this.state.newUser.role}
                onChange={this.handleRoleChange}
                fullWidth
                label="typ"
                inputProps={{
                  name: "role",
                  id: "role"
                }}
              >
                <MenuItem value="guest">{t("gosc")}</MenuItem>
                <MenuItem value="client">{t("klient")}</MenuItem>
                <MenuItem value="clientManager">Manager</MenuItem>
              </Select>
              <FormHelperText>
                <strong>{t("gosc")}</strong> - nie widzi przejazdów starszych niż 30 dni.
                <br />
                <strong>{t("klient")}</strong> - może zamówić przejazd na koszt firmy
                <br />
                <strong>Manager</strong> - może zamówić przejazd, widzi
                wszystkie zamówienia wewnątrz firmy oraz może zarządzać
                użytkownikami jeśli zostanie uprawniony.
              </FormHelperText>
              <FormControlLabel
                  control={
                    <Switch
                      checked={newUser.canCashless ? true : false}
                      onChange={this.handleCanCashlessSwitchChange}
                      value="canCashless"
                      color="secondary"
                    />
                  }
                  label={
                    newUser.canCashless
                      ? t("mozebezgotowka")
                      : t("niemozebezgotowka")
                  }
                />
              {this.state.newUser.role === "clientManager" ? (
                <FormControlLabel
                  control={
                    <Switch
                      checked={newUser.canCreateUsers ? true : false}
                      onChange={this.handleCanCreateUsersSwitchChange}
                      value="canCreateUsers"
                      color="secondary"
                    />
                  }
                  label={
                    newUser.canCreateUsers
                      ? t("mozezarzadzac")
                      : t("niemozezarzadzac")
                  }
                />
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleFormClose} color="primary">
                {t("anuluj")}
              </Button>
              <div className={classes.relative}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={formBusy}
                >
                  {t("zapisz")}
                </Button>
                {formBusy && (
                  <CircularProgress
                    size={24}
                    color="secondary"
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
        <Paper className={classes.root}>
          <Table padding="dense">
            <TableHead>
              <TableRow>
                <TableCell>{t("nazwa")}</TableCell>
                <TableCell>Login</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>{t("telefon")}</TableCell>
                <TableCell>{t("typ")}</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map(data => {
                return (
                  <UserRow
                    key={data.id}
                    data={data}
                    onEdit={() => this.openEditHandler(data)}
                  />
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  addButton: {
    position: "fixed",
    top: "68px",
    right: "20px",
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      top: "auto",
      bottom: "20px"
    }
  },
  roleInput: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  relative: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

export default withSnackbar(withStyles(styles)(withTranslation("translations")(Users)));
