import React, { Component } from 'react';

import Dialog from '@material-ui/core/Dialog';

class SimpleDialog extends Component {

    render() {
      const { open, content,  ...other } = this.props;

      return (
        <Dialog {...other} open={open} onClose={() => this.props.onClose()}>
           {content}
        </Dialog>
      );
    }
  }

  export default SimpleDialog;
